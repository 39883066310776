import type { IBusinessUser } from 'views/user-roles';
import type {
	IBusinessRegistrationState,
	ICompanyDetails,
	ILoginState,
	ILoginUser,
	IPayment,
	IUserState,
	IUserVerificationId,
	UserInviteType,
} from '.';

import { atom } from 'recoil';

import { ROLE } from 'constant';

const getCookie = (key: string) => {
	const name = key + '=';
	const decodedCookie = decodeURIComponent(document.cookie);
	const decodeCookie = decodedCookie.split(';');
	for (let i = 0; i < decodeCookie.length; i++) {
		let decodeKey: any = decodeCookie[i];
		while (decodeKey.charAt(0) === ' ') {
			decodeKey = decodeKey.substring(1);
		}
		if (decodeKey.indexOf(name) === 0) {
			return decodeKey.substring(name.length, decodeKey.length);
		}
	}
	return '';
};

const getUser = () => {
	try {
		const localStorageData = JSON.parse(getCookie('user') ?? '{}');
		const { OWNER } = ROLE;
		const {
			accessToken,
			role = OWNER,
			// loggedIn = false,
			developer,
			plan,
			business,
			status,
			linkedInUrl,
			token,
			approveStatus,
			passKeyCount,
			sandboxStatus,
		} = localStorageData ?? {};
		const checkoutStorageData = JSON.parse(
			localStorage.getItem('checkout') as any
		);
		const { id, paymentStatus, paymentIntent } = checkoutStorageData ?? {};
		return {
			user: {
				accessToken: accessToken ?? token ?? '',
				loggedIn: !!(accessToken ?? token).length ?? false,
				role: role,
				developer: developer ?? ',',
				plan: plan ?? '',
				webToken: '',
				business: business,
				status,
				linkedInUrl: linkedInUrl ?? '',
				firstName: '',
				lastName: '',
				countryCode: '',
				phone: '',
				image: '',
				sandboxStatus,
				approveStatus: approveStatus ?? 'PENDING',
				passKeyCount: passKeyCount ?? 0,
			},
			payment: {
				id: id ?? '',
				paymentStatus: paymentStatus ?? '',
				paymentIntent: paymentIntent ?? '',
			},
		};
	} catch (error) {
		// DOMException: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
		if (/Access is denied/gi.test((error as any).message)) {
			// TODO: @awadhesh add logic to get user data (API call)
		}
		return {
			user: {
				accessToken: '',
				loggedIn: false,
				role: ROLE.OWNER,
				developer: false,
				business: '',
				plan: '',
				webToken: '',
				status: '',
				linkedInUrl: '',
				firstName: '',
				lastName: '',
				countryCode: '',
				phone: '',
				image: '',
				approveStatus: 'PENDING',
				passKeyCount: 0,
			},
			payment: {
				id: '',
				paymentStatus: '',
				paymentIntent: '',
			},
		};
	}
};

export const loginState = atom<ILoginState>({
	key: 'login',
	default: getUser().user,
});

export const userState = atom<IUserState>({
	key: 'user-state',
	default: {
		userId: '',
	},
});

export const BusinessRegistrationState = atom<IBusinessRegistrationState | any>(
	{
		key: 'business-registration-state',
		default: null,
	}
);

export const BannerStatusState = atom<boolean | null>({
	key: 'is-show-banner-key',
	default: null,
});

export const IsBannerStatusState = atom<boolean | null>({
	key: 'is-banner-status-key',
	default: null,
});

export const companyDetailsState = atom<ICompanyDetails>({
	key: 'company-state',
	default: {
		name: '',
		address: {
			address1: '',
			address2: '',
			zip: '',
			city: '',
			state: '',
		},
		logo: '',
		linkedInUrl: '',
		purposeOfUsing: '',
		billingType: null,
		billingAddress: {
			address1: '',
			address2: '',
			zip: '',
			city: '',
			state: '',
		},
	},
});

export const subscriptionServiceState = atom<any>({
	key: 'subscription-service-state-key',
	default: null,
});

export const paymentState = atom<IPayment>({
	key: 'payment-state',
	default: getUser().payment,
});

export interface IInvitedUsersDetails extends IBusinessUser {
	funds: { label: string; value: string }[];
	subAccount: { label: string; value: string };
}

export const InviteDetailsState = atom<UserInviteType | Record<string, never>>({
	key: 'invite-user-role-state',
	default: {},
});

export interface IPhoneNumber {
	phone: string;
	countryCode: string | number;
}
const user = { phone: '', token: '', countryCode: '', isLoggedIn: false };

export interface IInviteEmail {
	email: string;
}

export const SendOTPState = atom<boolean>({
	key: 'send-otp',
	default: false,
});

export const LoginUserState = atom<ILoginUser>({
	key: 'login-user-data',
	default: {
		phone: user?.phone ?? '',
		countryCode: user?.countryCode ?? '',
		token: user?.token ?? '',
		isLoggedIn: user?.isLoggedIn ?? false,
	},
});

export const LoginPhoneNumberState = atom<IPhoneNumber>({
	key: 'login-phone-state',
	default: {
		phone: '',
		countryCode: '+1',
	},
});

export const InviteFirstNameInputState = atom({
	key: 'invite-first-name-input-key',
	default: false,
});

export const InviteEmailInputState = atom({
	key: 'invite-email-input-key-error',
	default: false,
});

export const InvitePhoneInputState = atom({
	key: 'invite-phone-input-key',
	default: "",
});

export const InviteEmailState = atom({
	key: 'invite-email-input-key',
	default: false,
});

export const InviteLastNameInputState = atom({
	key: 'invite-last-name-input-key',
	default: false,
});
export const InviteFundState = atom({
	key: 'invite-funds-input-key',
	default: false,
});

export const InviteRoleState = atom<boolean>({
	key: 'invite-role-input-key',
	default: false,
});

export const UserRoleState = atom<IBusinessUser[]>({
	key: 'user-role-data-state',
	default: [],
});

export const InviteModal = atom<boolean>({
	key: 'invite-modal',
	default: false,
});
export const EditModal = atom<boolean>({
	key: 'edit-modal',
	default: false,
});

export const userDataState = atom<any>({
	key: 'user-data',
	default: {},
});

export const IsCompanyVerifiedState = atom<boolean>({
	key: 'is-company-veryfied-state',
	default: true,
});

export const OtpEmailState = atom<string>({
	key: 'otp-email-state',
	default: '',
});

export const ForgotPageState = atom<string>({
	key: 'forgot-page-state',
	default: 'forgot',
});

export const NewGmailState = atom<string>({
	key: 'new-gmail-state',
	default: '',
});

export const isWebAuthnSupported = atom<boolean>({
	key: 'is-web-authn-supported',
	default: false,
});

export const userVerificationId = atom<IUserVerificationId>({
	key: 'user-verification-id',
	default: {
		phoneVerificationId: '',
	},
});

export const userDataWithOutToken = atom<any>({
	key: 'user-data-without-token',
	default: {
		localStorageData: {},
		cookieData: {},
	},
});
