import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { capitalize } from 'utils';
import { AppleID } from '../apple-id';
import { GoogleMap } from '../google-map';
import { SessionInformationLoader } from 'views/sessions/session-details-loader';
import { NoData } from '../no-data-available';
import {
	ComplexSessionInfoState,
	ConditionalSessionFlowState,
	SelectedSessionState,
	SelectedSessionsIdState,
} from 'global-stores';
import Tippy from '@tippyjs/react';
import { FacialInformationSection } from '../facial-section';

interface ISessionInfo {
	loaded?: boolean;
	isLoading?: boolean;
	sessionData?: any;
}

const SessionInfo = ({ loaded, isLoading, sessionData }: ISessionInfo) => {
	const selectedSession = useRecoilValue(SelectedSessionState);
	const isComplexFlow = useRecoilValue(ConditionalSessionFlowState);
	const complexSessionInfo = useRecoilValue(ComplexSessionInfoState);
	const selectedIdState = useRecoilValue(SelectedSessionsIdState);
	const { deviceInfo, sessionMetadata, geoLocation, userInfo } = useMemo(() => {
		if (isComplexFlow) {
			return (sessionData ?? complexSessionInfo)?.metadata ?? ({} as any);
		}
		return (
			selectedSession?.device ?? selectedSession?.deviceInfo ?? ({} as any)
		);
	}, [
		isComplexFlow,
		selectedSession?.device,
		selectedSession?.deviceInfo,
		sessionData,
		complexSessionInfo,
	]);
	const { countryCode, phone } = useMemo(() => userInfo ?? {}, [userInfo]);
	const isDeviceInfoAvailable = useMemo(() => {
		return !!(Object.keys(deviceInfo ?? {})?.length < 0);
	}, [deviceInfo]);

	const { ipAddress, systemData, userAddress } = sessionMetadata ?? {};

	const { browser, device, os } = systemData ?? {};

	const { city, country_code, region } = userAddress ?? {};

	const geoLocationAvailable = useMemo(() => {
		const { latitude } = geoLocation ?? {};
		return !!latitude;
	}, [geoLocation]);

	const isInfoAvailable = useMemo(() => {
		const { name } = deviceInfo ?? {};
		return !!name;
	}, [deviceInfo]);

	const nameEmail = useMemo(() => {
		const { name = 'NA', email = 'NA' } = deviceInfo ?? {};
		return { name, email };
	}, [deviceInfo]);

	return (
		<div className="session-info">
			<div className="kycDetails-header">
				<div className="kycDetails--sub-header">Session Information </div>
				<span className="kycDetails-header__sessionId">
					<span className="kycDetails-header__sessionId__label">ID </span>:{' '}
					{selectedIdState}
				</span>
			</div>
			{/* Design was not confirm so made last moment changes and commented some code for confirmation  */}
			{!isLoading && loaded ? (
				<>
					{!!sessionMetadata ||
					geoLocationAvailable ||
					isInfoAvailable ||
					!isDeviceInfoAvailable ? (
						<>
							<div className="session-info__header">
								<div className="session-info__header__icon-container">
									<i
										className={
											device?.type
												? device?.type === 'desktop'
													? 'ri-macbook-line'
													: 'ri-smartphone-line'
												: 'ri-macbook-line'
										}
									/>
									<span>
										{device?.type || deviceInfo?.type
											? capitalize(device?.type ?? deviceInfo?.type)
											: 'Desktop'}
									</span>
								</div>
								{/* <div className="date">time and date</div> */}
							</div>

							<div className="auth-selfie-image__details-box">
								<div className="session-info__body__details apple-id_info">
									<div className="session-info__body__icon-container device">
										<i className="ri-user-line" />
									</div>
									<div className="session-info__body__detailed">
										<span className="session-info__body__key">Name</span>
										<span className="session-info__body__value auth-selfie-image__details-value">
											{nameEmail.name}
										</span>
									</div>
								</div>
								<div className="session-info__body__details apple-id_info">
									<div className="session-info__body__icon-container browser">
										<i className="ri-mail-line" />
									</div>
									<div className="session-info__body__detailed">
										<span className="session-info__body__key">Email</span>
										<span className="session-info__body__value auth-selfie-image__details-value">
											<Tippy content={nameEmail.email}>
												<div>{nameEmail.email}</div>
											</Tippy>
										</span>
									</div>
								</div>
								<div className="session-info__body__details apple-id_info">
									<div className="session-info__body__icon-container user">
										<i className="ri-contacts-book-2-line"></i>
									</div>
									<div className="session-info__body__detailed">
										<span className="session-info__body__key">Phone</span>
										<span className="session-info__body__value auth-selfie-image__details-value">
											{countryCode || phone ? `${countryCode} ${phone}` : 'NA'}
										</span>
									</div>
								</div>
							</div>
							<div className="session-info__body">
								<div className="session-info__body__details-container">
									<div className="session-info__body__details">
										<div className="session-info__body__icon-container device">
											<i className="ri-smartphone-line" />
										</div>
										<div className="session-info__body__detailed">
											<span className="session-info__body__key">Device OS</span>
											<span className="session-info__body__value">
												{os?.name && os?.version
													? `${os?.name} ${os?.version}`
													: 'NA'}
											</span>
										</div>
									</div>

									<div className="session-info__body__details">
										<div className="session-info__body__icon-container user">
											<i className="ri-user-search-line" />
										</div>
										<div className="session-info__body__detailed">
											<span className="session-info__body__key">User IP</span>
											<span className="session-info__body__value">
												{ipAddress || 'NA'}
											</span>
										</div>
									</div>
									<div className="session-info__body__details">
										<div className="session-info__body__icon-container host">
											<i className="ri-map-pin-2-line" />
										</div>
										<div className="session-info__body__detailed">
											<span className="session-info__body__key">Region</span>
											<span className="session-info__body__value">
												{(region ?? 'NA').toUpperCase()}
											</span>
										</div>
									</div>
								</div>
								<div className="session-info__body__details-container">
									<div className="session-info__body__details">
										<div className="session-info__body__icon-container browser">
											<i className="ri-global-line" />
										</div>
										<div className="session-info__body__detailed">
											<span className="session-info__body__key">Browser</span>
											<span className="session-info__body__value">
												{browser?.name && browser?.major
													? `${browser?.name} ${browser?.major}`
													: 'NA'}
											</span>
										</div>
									</div>

									<div className="session-info__body__details">
										<div className="session-info__body__icon-container location">
											<i className="ri-user-location-line" />
										</div>
										<div className="session-info__body__detailed">
											<span className="session-info__body__key">City</span>
											<span className="session-info__body__value">
												{city ? capitalize(city) : 'NA'}
											</span>
										</div>
									</div>
									<div className="session-info__body__details">
										<div className="session-info__body__icon-container referrer">
											<i className="ri-flag-line" />
										</div>
										<div className="session-info__body__detailed">
											<span className="session-info__body__key">
												Country Code
											</span>
											<span
												className="session-info__body__value"
												style={{ textTransform: 'uppercase' }}
											>
												{country_code ?? 'NA'}
											</span>
										</div>
									</div>
								</div>
							</div>
							<AppleID sessionData={sessionData} />

							<GoogleMap sessionData={sessionData} />
							<FacialInformationSection />
						</>
					) : (
						<NoData />
					)}
				</>
			) : (
				<SessionInformationLoader />
			)}
		</div>
	);
};

export { SessionInfo };
