import { Button, Loader } from '@storybook';
import { useTrackEvents } from 'helpers';
import { useNotification } from 'hooks';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { ROUTES } from 'routes';
import {
	ActiveActionKeyNameState,
	BannerStatusState,
	IsOpenInIframeState,
	IsPipelineFlowErrorMessage,
	PipelineMasterSteps,
	PipelineSettingsFormState,
	PipelineTemplateVisibleState,
	SelectedMultipleDateState,
	isChecqueFraudSelected,
} from 'global-stores';
import {
	ComplexConfigFromState,
	ComplexConfigModalState,
	ComplexErrorSteps,
	ComplexFlowUnconfigedState,
	EdgesState,
	NodesState,
} from 'views/complex-onboarding-flow/stores/states';
import {
	AddedActionsState,
	AddedFileDataState,
	ConfigureSurvayFormState,
	ConfiguredStepState,
	CurrentStepState,
	IsConfigurationChecking,
	OnboardingFooterNavigateState,
	SelectedCheckboxStepsState,
	SelectedRadioState,
	UnConfiguredStep,
} from '../../store';
import { usePublish } from '../publish/publish-api';
import './footer-navigate.scss';
import { useSetComplex } from 'views/complex-onboarding-flow/hooks/use-complex';
import { OnboardingFlowBackModel } from 'components';
import { useComplexLayout } from 'views/complex-onboarding-flow';
import { NODES_MESSAGE } from 'views/onboarding-flow/constants';
import {
	UnitPriceInputErrorState,
	UnitPriceInputValueState,
	UnitPriceToggleState,
} from 'views/pipeline';

export const FooterNavigate = () => {
	const settingsForm = useRecoilValue(PipelineSettingsFormState);
	const [navigate, setNavigate] = useRecoilState(OnboardingFooterNavigateState);
	const steps = useRecoilValue(PipelineMasterSteps);
	const resetAddedAction = useResetRecoilState(AddedActionsState);
	const addedActions = useRecoilValue(AddedActionsState);
	const setIsVisibleTemplate = useSetRecoilState(PipelineTemplateVisibleState);
	const setActiveAction = useSetRecoilState(ActiveActionKeyNameState);
	const setIsConfigModal = useSetRecoilState(ComplexConfigModalState);
	const [currentStep, setCurrentStep] = useRecoilState(CurrentStepState);
	const resetNavigate = useResetRecoilState(OnboardingFooterNavigateState);
	const resetInputCheckbox = useResetRecoilState(SelectedCheckboxStepsState);
	const resetAddedStep = useResetRecoilState(AddedActionsState);
	const resetAddedFile = useResetRecoilState(AddedFileDataState);
	const resetConfiguredStep = useResetRecoilState(ConfiguredStepState);
	const resetIsChecking = useResetRecoilState(IsConfigurationChecking);
	const [unOpennedConfig, setUnOpennedConfig] =
		useRecoilState(UnConfiguredStep);
	const setIsChecking = useSetRecoilState(IsConfigurationChecking);
	const isIframeOpen = useRecoilValue(IsOpenInIframeState);
	const addedAccreditationDoc = useRecoilValue(AddedFileDataState);
	const isVerified = useRecoilValue(BannerStatusState);
	const resetFormStepState = useResetRecoilState(ConfigureSurvayFormState);
	const { configured, type } = addedAccreditationDoc;
	const setComplexConfigFromState = useSetRecoilState(ComplexConfigFromState);
	const resetComplexConfigFromState = useResetRecoilState(
		ComplexConfigFromState
	);
	const resetComplexConfigModalState = useResetRecoilState(
		ComplexConfigModalState
	);
	const setComplexErrorSteps = useSetRecoilState(ComplexErrorSteps);

	const isChequeFraudSelected = useRecoilValue(isChecqueFraudSelected);

	const complexFlowConfiged = useRecoilValue(ComplexFlowUnconfigedState);

	const resetComplexFlowConfiged = useResetRecoilState(
		ComplexFlowUnconfigedState
	);
	const resetSelectedRadioLabel = useResetRecoilState(SelectedRadioState);

	const [nodes, setNodes] = useRecoilState(NodesState);
	const [edges, setEdges] = useRecoilState(EdgesState);
	const [isBackModel, setIsBackModal] = useState(false);

	const { trackEvents } = useTrackEvents();
	const { validateConditions } = useComplexLayout();

	const setSelectedMultipleDate = useSetRecoilState(SelectedMultipleDateState);

	const routeNavigate = useNavigate();
	const { PIPELINE } = ROUTES;
	const { onPublish, creatingPipeline } = usePublish();
	const { errorNotification, warningNotification } = useNotification();
	const { setNextStep } = useSetComplex();
	const [isBackLoading, setIsBackLoading] = useState(false);
	const pipelineFlowErrorMessage = useRecoilValue(IsPipelineFlowErrorMessage);
	const resetUnitPriceInputError = useResetRecoilState(
		UnitPriceInputErrorState
	);
	const resetUnitPriceToggle = useResetRecoilState(UnitPriceToggleState);
	const resetUnitPriceInputValue = useResetRecoilState(
		UnitPriceInputValueState
	);

	useEffect(() => {
		const handleBeforeUnload = (event: { returnValue: string }) => {
			event.returnValue =
				'You have unsaved changes. Are you sure you want to refresh?';
		};
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	// Awadhesh: Disable broswer back button and open custom modal
	useEffect(() => {
		window.history.pushState(null, '', window.location.href);
		window.onpopstate = () => {
			window.history.pushState(null, '', window.location.href);
			setIsBackModal(true);
		};
	}, []);

	const handlerExit = useCallback(() => {
		setIsVisibleTemplate(false);
		resetNavigate();
		resetInputCheckbox();
		resetAddedStep();
		resetFormStepState();
		setSelectedMultipleDate([]);
		resetUnitPriceInputError();
		resetUnitPriceInputValue();
		resetUnitPriceToggle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isAllConfiged = useMemo(
		() => !!complexFlowConfiged.find(el => !el.configed),
		[complexFlowConfiged]
	);

	const renderFooterNext = useCallback(() => {
		if (!isVerified) {
			errorNotification('Please verify your email to create onboarding.');
			return;
		}

		switch (navigate) {
			case 'complex-flow': {
				const { id, valid } = validateConditions(nodes);
				setComplexErrorSteps(id);
				if (!valid) {
					errorNotification(NODES_MESSAGE.validationFailed);
					return;
				}

				const roots = nodes.filter((el: any) => el.parentId.length === 0);

				if (roots.length > 1) {
					errorNotification(
						'Each action must have a connected start node for the flow to be built.'
					);
					return;
				}

				//Avinash : In complex flow user can not create flow with sigle node where stepid is aml i will be required kyc
				if (
					(!nodes?.length || nodes?.length === 1) &&
					nodes[0]?.stepId === 'aml'
				) {
					errorNotification('adding "KYC" before "AML." ');
					return;
				}

				if (isAllConfiged) {
					const pendingFlow = complexFlowConfiged.find(flow => !flow.configed);
					if (pendingFlow) {
						setComplexConfigFromState('NEXT');
						setNextStep();
						warningNotification('Please configure steps.');
						return;
					}
				}

				if (edges.length < nodes.length - 1) {
					errorNotification(
						'All nodes are not connected please connect to root node'
					);
					return;
				}

				trackEvents('flow-builder', {});
				setIsChecking(false);
				setNavigate(isChequeFraudSelected ? 'publish' : 'deliveryMethod');
				return;
			}
			case 'deliveryMethod':
				trackEvents('flow-delivery-method', {});
				if (currentStep?.key === 'deliveryMethodMessage') {
					const step =
						steps.find(step => step?.key === 'deliveryMethod')?.actions ?? [];
					const toEmail: any = step.filter(
						item => item?.key === 'deliveryMethodEmail'
					);
					setCurrentStep(toEmail[0]);
				} else if (currentStep?.key === 'deliveryMethodEmail') {
					const step =
						steps.find(step => step?.key === 'deliveryMethod')?.actions ?? [];
					const reminder: any = step.filter(
						item => item?.key === 'reminderSetting'
					);
					setCurrentStep(reminder[0]);
				} else if (
					currentStep?.key === 'reminderSetting' &&
					settingsForm.enableReminderSetting &&
					settingsForm.reminderSettingTrigger.length === 0
				) {
					errorNotification('Please select trigger.');
				} else {
					return setNavigate('publish');
				}
				break;
			case 'publish':
				if (Object.values(pipelineFlowErrorMessage).some(check => check)) {
					return;
				}
				onPublish();
				setSelectedMultipleDate([]);
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		configured,
		type,
		isVerified,
		addedActions,
		navigate,
		errorNotification,
		isAllConfiged,
		edges?.length,
		nodes,
		trackEvents,
		setIsChecking,
		setNavigate,
		unOpennedConfig?.length,
		currentStep?.key,
		settingsForm?.enableReminderSetting,
		settingsForm?.reminderSettingTrigger?.length,
		onPublish,
		setSelectedMultipleDate,
		complexFlowConfiged,
		setIsConfigModal,
		steps,
		setCurrentStep,
		setActiveAction,
		warningNotification,
		isChequeFraudSelected,
		pipelineFlowErrorMessage,
	]);
	const handleProceedBack = () => {
		resetComplexConfigModalState();
		setIsBackLoading(true);
		const navigateFrom = ['deliveryMethod', 'publish'];
		setTimeout(() => {
			if (navigateFrom.includes(navigate)) {
				resetComplexConfigFromState();
				resetComplexConfigModalState();
				resetComplexFlowConfiged();
				resetConfiguredStep();
				resetIsChecking();
				resetAddedAction();
				setIsChecking(false);
				setUnOpennedConfig(['default']);
				resetSelectedRadioLabel();
				handlerExit();
				return routeNavigate(PIPELINE);
			}
		}, 1000);
		setTimeout(() => {
			if (navigate === 'complex-flow') {
				resetAddedFile();
				resetConfiguredStep();
				resetComplexFlowConfiged();
				resetIsChecking();
				resetAddedAction();
				setIsChecking(false);
				setUnOpennedConfig(['default']);
				setEdges([]);
				setNodes([]);
				resetSelectedRadioLabel();
				handlerExit();
				return routeNavigate(PIPELINE);
			}
		});
	};

	const handleInput = () => {
		setIsBackModal(false);
	};
	const renderFooterBack = useCallback(() => {
		switch (navigate) {
			case 'deliveryMethod':
				if (currentStep?.key === 'deliveryMethodMessage') {
					setNavigate('complex-flow');
				} else {
					if (currentStep?.key === 'deliveryMethodEmail') {
						const step =
							steps.find(step => step.key === 'deliveryMethod')?.actions ?? [];
						const toTextMessage: any = step.filter(
							item => item.key === 'deliveryMethodMessage'
						);
						setCurrentStep(toTextMessage[0]);
					}
					if (currentStep?.key === 'reminderSetting') {
						const step =
							steps.find(step => step.key === 'deliveryMethod')?.actions ?? [];
						const toEmail: any = step.filter(
							item => item.key === 'deliveryMethodEmail'
						);
						setCurrentStep(toEmail[0]);
					}
				}
				break;
			case 'publish':
				if (currentStep?.key === 'publishOnboarding') {
					if (!isChequeFraudSelected) {
						setNavigate('deliveryMethod');
					} else {
						setNavigate('complex-flow');
					}
				} else {
					const step =
						steps.find(step => step.key === 'publish')?.actions ?? [];
					const additionalSetting: any = step.filter(
						item => item.key === 'publishOnboarding'
					);
					setCurrentStep(additionalSetting[0]);
				}
				break;
			case 'complex-flow':
				setIsBackModal(true);
				return;
			default:
				break;
		}
	}, [
		navigate,
		currentStep?.key,
		isChequeFraudSelected,
		setNavigate,
		steps,
		setCurrentStep,
	]);

	const handleDisableNext = useCallback(() => {
		switch (navigate) {
			case 'complex-flow':
				return !addedActions.length || !nodes.length;
			case 'deliveryMethod':
				return false;
			case 'publish':
				return (
					Object.values(pipelineFlowErrorMessage).some(check => check) ||
					creatingPipeline
				);
			default:
				return false;
		}
	}, [
		pipelineFlowErrorMessage,
		navigate,
		addedActions.length,
		nodes.length,
		creatingPipeline,
	]);

	return (
		<Fragment>
			<div className="FooterNavigate--container">
				<div className="FooterNavigate--right">
					{!(isIframeOpen && navigate === 'complex-flow') && (
						<Button
							handleClick={renderFooterBack}
							label="Back"
							type="button__ghost button__filled--secondary"
							buttonType="button"
						/>
					)}

					<Button
						handleClick={renderFooterNext}
						label={
							creatingPipeline ? (
								<Loader type="loader" dimension={20} className="loader-white" />
							) : navigate === 'publish' ? (
								'Publish'
							) : (
								'Next'
							)
						}
						type="button__filled button__filled--primary"
						buttonType="button"
						disabled={handleDisableNext()}
					/>
				</div>
			</div>
			<OnboardingFlowBackModel
				isOpen={isBackModel}
				isLoaded={isBackLoading}
				handleProceedBack={handleProceedBack}
				handleInput={handleInput}
			/>
		</Fragment>
	);
};
